import { ComponentType } from "react"
import { fetchGPTS, fetchIp } from "./ApiVercel.tsx"
import React, { useState, useEffect } from "react"
import { createContext, useContext, useReducer, useRef } from "react"

type MyContextType = {}

const numPhrases = 20
const phrases = [
    "...Sabia que a Magma Translation traduz 200 mil palavras todos os dias?",
    "...Temos uma plataforma de traduções com pedidos online, confira.",
    "...Cante como se ninuém estivesse escutando.",
    "Frase 4",
    "Frase 5",
    "Frase 6",
    "Frase 7",
    "Frase 8",
    "Frase 9",
    "Frase 10",
    "Frase 11",
    "Frase 12",
    "Frase 13",
    "Frase 14",
    "Frase 15",
    "Frase 16",
    "Frase 17",
    "Frase 18",
    "Frase 19",
    "Frase 20",
]

const fontSizeResponse = "15px"

const token = 50

const MyContext = createContext<MyContextType | undefined>(undefined)

export function mainContext(Component): ComponentType {
    return (props) => {
        const [input, setInput] = useState("")
        const [value, setValue] = useState("")
        const [open, setOpen] = useState(false)
        const [ip, setIp] = useState("")
        useEffect(() => {
            localStorage.removeItem("thread")
        }, [])
        useEffect(() => {
            fetchIp().then((res) => setIp(res))
        }, [])
        console.log(ip)
        return (
            <MyContext.Provider
                value={{ input, setInput, value, setValue, open, setOpen, ip }}
            >
                {" "}
                <Component {...props} />{" "}
            </MyContext.Provider>
        )
    }
}
function convertTextToLinks(text) {
    const urlRegex = /https?:\/\/[^\s/$.?#].[^\s]*/g
    return text.replace(urlRegex, function (url) {
        const gh = url.split(".").includes("https://app")
        // console.log(, url.split("."))
        return `<a href="${
            gh
                ? "https://app.magmatranslation.com"
                : "https://magmatranslation.com"
        }" target="_blank">${url}</a>`
    })
}
export function button(Component): ComponentType {
    return (props) => {
        const { input, setInput, value, setValue, open, setOpen } = useContext(
            MyContext
        ) || {
            input: "",
            setInput: "",
            value: "",
            setValue: "",
        }
        const handleClick = async () => {
            setOpen(true)
            const data = await fetchGPTS(input, token)
            setValue(convertTextToLinks(data.message))
            setOpen(false)
        }
        return <Component onClick={handleClick} {...props} />
    }
}

function getRandomNumber() {
    return Math.floor(Math.random() * (numPhrases + 1))
}

export function textH2(Component): ComponentType {
    return (props) => {
        const { value, open } = useContext(MyContext) || {
            value: "",
            open: true,
        }
        const [text, setText] = useState("")
        if (open) {
            return (
                <Component {...props}>
                    {" "}
                    <h2 style={styleReponse}>
                        {phrases[getRandomNumber()]}
                    </h2>{" "}
                </Component>
            )
        }

        return (
            <Component {...props}>
                {" "}
                <h2
                    style={styleReponse}
                    dangerouslySetInnerHTML={{ __html: value }}
                />{" "}
            </Component>
        )
    }
}
export function input(Component): ComponentType {
    return (props) => {
        const { input, setInput } = useContext(MyContext) || {
            input: "",
            setInput: "",
        }
        const handleChange = (e) => {
            setInput(e.target.value)
        }
        return (
            <Component {...props}>
                <textarea
                    className="scroll-container"
                    onChange={handleChange}
                    placeholder={"Pergunte para o site"}
                    style={styleInput}
                >
                    {input}
                </textarea>
                <style>{`
                    .scroll-container::-webkit-scrollbar {
                    display: none;
                    }
                    .scroll-container {
                    -ms-overflow-style: none; /* IE and Edge */
                    scrollbar-width: none; /* Firefox */
                    }
                `}</style>
            </Component>
        )
    }
}

const styleInput = {
    width: "80%",
    overflowY: "auto",
    height: "100%",
    outline: "none",
    resize: "none",
    border: "none",
    color: "white",
    backgroundColor: "transparent",
    fontFamily: "Arial",
    fontWeight: "100",
    fontSize: "15px",
    padding: "10px 20px",
    letterSpacing: "",
    lineHeight: "25px",
    wordSpacing: "1px",

    "::placeholder": {
        fonColor: "white",
    },
}

const styleReponse = {
    fontFamily: "Arial",
    fontWeight: "100",
    fontSize: "15px",
    color: "white",
    letterSpacing: "",
    lineHeight: "25px",
    wordSpacing: "1px",
}
